import { jsxs as b, jsx as t, Fragment as Y } from "react/jsx-runtime";
import { AlertGroup as tt, Alert as nt, AlertVariant as re, AlertActionCloseButton as rt, Page as at, Modal as De, ModalVariant as lt, Button as P, TextContent as it, Text as le, TextVariants as st, Spinner as ot, FormHelperText as ue, HelperText as he, HelperTextItem as me, Popover as ct, Icon as ie, FormGroup as Ve, NumberInput as dt, ValidatedOptions as M, InputGroup as pe, InputGroupItem as se, TextInput as Q, Select as X, MenuToggle as K, MenuToggleStatus as fe, SelectList as Z, SelectOption as ge, TextInputGroup as Me, TextInputGroupMain as Ne, ChipGroup as Le, Chip as qe, TextInputGroupUtilities as Be, Switch as ut, TextArea as _e, Title as ht, Card as mt, CardHeader as pt, CardTitle as ft, CardBody as gt, Grid as yt, GridItem as xe, PageSection as vt, JumpLinks as bt, JumpLinksItem as Ct, ButtonVariant as we, Checkbox as It, Radio as kt, MenuFooter as xt, Dropdown as wt, DropdownList as At, DropdownItem as Ae, Brand as Tt, Avatar as St } from "@patternfly/react-core";
import { createContext as ye, useContext as ve, useState as S, useCallback as He, useEffect as be, useMemo as D, useRef as ee, forwardRef as Ot, useId as Ft, Fragment as Ue, Children as $e } from "react";
import { useTranslation as Ce } from "react-i18next";
import Rt from "keycloak-js";
import { ExclamationCircleIcon as Et, HelpIcon as Te, EyeIcon as Pt, EyeSlashIcon as Dt, TimesIcon as Ge, CubeIcon as Vt, PaypalIcon as Mt, InstagramIcon as Nt, BitbucketIcon as Lt, MicrosoftIcon as qt, TwitterIcon as Bt, StackOverflowIcon as _t, OpenshiftIcon as Ht, LinkedinIcon as Ut, GoogleIcon as $t, GitlabIcon as Gt, FacebookSquareIcon as Kt, GithubIcon as Wt, MinusCircleIcon as jt, PlusCircleIcon as zt, EllipsisVIcon as Jt } from "@patternfly/react-icons";
import { useFormContext as te, Controller as U, useController as Ie, FormProvider as Yt, useWatch as Qt } from "react-hook-form";
import { get as G } from "lodash-es";
import { PageHeader as Xt, PageHeaderTools as Zt, PageHeaderToolsGroup as en, PageHeaderToolsItem as ae } from "@patternfly/react-core/deprecated";
import j from "@patternfly/react-styles/css/components/Avatar/avatar";
import { css as tn } from "@patternfly/react-styles";
import './main.css';const Ke = ye(void 0), ar = () => ve(Ke), nn = ({ children: e }) => {
  const [n, r] = S([]), a = (s) => {
    r((c) => c.filter((o) => o.id !== s));
  }, l = (s, c = re.success, o) => {
    r([
      {
        id: Math.random() * 100,
        message: s,
        variant: c,
        description: o
      },
      ...n
    ]);
  }, i = (s) => {
    l(s, re.danger);
  };
  return /* @__PURE__ */ b(Ke.Provider, { value: { addAlert: l, addError: i }, children: [
    /* @__PURE__ */ t(tt, { isToast: !0, "data-testid": "alerts", children: n.map(({ id: s, variant: c, message: o, description: m }) => /* @__PURE__ */ t(
      nt,
      {
        isLiveRegion: !0,
        variant: re[c],
        variantLabel: "",
        title: o,
        actionClose: /* @__PURE__ */ t(
          rt,
          {
            title: o,
            onClose: () => a(s)
          }
        ),
        timeout: !0,
        onTimeout: () => a(s),
        children: m && /* @__PURE__ */ t("p", { children: m })
      },
      s
    )) }),
    e
  ] });
}, rn = (e) => {
  const { t: n } = Ce(), r = e.error, a = an(r);
  function l() {
    location.href = location.origin + location.pathname;
  }
  return /* @__PURE__ */ t(at, { children: /* @__PURE__ */ t(
    De,
    {
      variant: lt.small,
      title: n("somethingWentWrong"),
      titleIconVariant: "danger",
      showClose: !1,
      isOpen: !0,
      actions: [
        /* @__PURE__ */ t(P, { variant: "primary", onClick: l, children: n("tryAgain") }, "tryAgain")
      ],
      children: /* @__PURE__ */ b(it, { children: [
        /* @__PURE__ */ t(le, { children: n("somethingWentWrongDescription") }),
        a && /* @__PURE__ */ t(le, { component: st.small, children: a })
      ] })
    }
  ) });
};
function an(e) {
  return typeof e == "string" ? e : e instanceof Error ? e.message : null;
}
function ln(e, n) {
  const r = ye(n);
  return r.displayName = e, r;
}
function sn(e) {
  return e != null;
}
function on(e) {
  const n = ve(e);
  if (sn(n))
    return n;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function cn(e, n, r) {
  const [a, l] = S(
    () => e.getItem(n) ?? r
  ), i = He((s) => {
    l(s), e.setItem(n, s);
  }, []);
  return be(() => {
    l(e.getItem(n) ?? r), window.addEventListener("storage", s);
    function s(c) {
      c.storageArea === e && (c.key === null || c.key === n) && l(c.newValue ?? r);
    }
    return () => window.removeEventListener("storage", s);
  }, [e, n]), [a, i];
}
function dn(e, n, r) {
  const a = D(
    () => JSON.stringify(r),
    [r]
  ), [l, i] = cn(
    e,
    n,
    a
  ), s = D(() => JSON.parse(l), [l]), c = He(
    (o) => i(JSON.stringify(o)),
    []
  );
  return [s, c];
}
const We = ln(
  "HelpContext",
  void 0
), un = () => on(We), hn = ({ children: e }) => {
  const [n, r] = dn(localStorage, "helpEnabled", !0);
  function a() {
    r(!n);
  }
  return /* @__PURE__ */ t(We.Provider, { value: { enabled: n, toggleHelp: a }, children: e });
}, mn = () => ye(void 0);
let oe;
const lr = () => {
  const e = ve(oe);
  if (!e)
    throw Error(
      "no environment provider in the hierarchy make sure to add the provider"
    );
  return e;
}, ir = ({
  environment: e,
  children: n
}) => {
  oe = mn();
  const r = ee(!1), [a, l] = S(!1), [i, s] = S(), c = D(() => {
    const o = new Rt({
      url: e.serverBaseUrl,
      realm: e.realm,
      clientId: e.clientId
    });
    return o.onAuthLogout = () => o.login(), o;
  }, [e]);
  return be(() => {
    if (r.current)
      return;
    c.init({
      onLoad: "check-sso",
      pkceMethod: "S256",
      responseMode: "query"
    }).then(() => l(!0)).catch((m) => s(m)), r.current = !0;
  }, [c]), i ? /* @__PURE__ */ t(rn, { error: i }) : a ? /* @__PURE__ */ t(oe.Provider, { value: { environment: e, keycloak: c }, children: /* @__PURE__ */ t(nn, { children: /* @__PURE__ */ t(hn, { children: n }) }) }) : /* @__PURE__ */ t(ot, {});
};
function sr() {
  const n = document.getElementById("environment")?.textContent;
  if (typeof n != "string")
    throw new Error("Environment variables not found in the document.");
  try {
    return JSON.parse(n);
  } catch {
    throw new Error("Unable to parse environment variables as JSON.");
  }
}
const or = ({
  modalTitle: e,
  continueLabel: n,
  cancelLabel: r,
  buttonTitle: a,
  isDisabled: l,
  buttonVariant: i,
  buttonTestRole: s,
  onContinue: c,
  component: o = P,
  children: m,
  ...f
}) => {
  const [h, d] = S(!1);
  return /* @__PURE__ */ b(Y, { children: [
    /* @__PURE__ */ t(
      o,
      {
        variant: i,
        onClick: () => d(!0),
        isDisabled: l,
        "data-testrole": s,
        children: a
      }
    ),
    /* @__PURE__ */ t(
      De,
      {
        variant: "small",
        ...f,
        title: e,
        isOpen: h,
        onClose: () => d(!1),
        actions: [
          /* @__PURE__ */ t(
            P,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                d(!1), c();
              },
              children: n
            },
            "confirm"
          ),
          /* @__PURE__ */ t(
            P,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => d(!1),
              children: r
            },
            "cancel"
          )
        ],
        children: m
      }
    )
  ] });
}, je = ({ message: e, ...n }) => /* @__PURE__ */ t(ue, { ...n, children: /* @__PURE__ */ t(he, { children: /* @__PURE__ */ t(me, { icon: /* @__PURE__ */ t(Et, {}), variant: "error", children: e }) }) }), ze = ({
  helpText: e,
  fieldLabelId: n,
  noVerticalAlign: r = !0,
  unWrap: a = !1
}) => {
  const { enabled: l } = un();
  return l ? /* @__PURE__ */ t(ct, { bodyContent: e, children: /* @__PURE__ */ b(Y, { children: [
    !a && /* @__PURE__ */ t(
      "button",
      {
        "data-testid": `help-label-${n}`,
        "aria-label": n,
        onClick: (i) => i.preventDefault(),
        className: "pf-v5-c-form__group-label-help",
        children: /* @__PURE__ */ t(ie, { isInline: r, children: /* @__PURE__ */ t(Te, {}) })
      }
    ),
    a && /* @__PURE__ */ t(ie, { isInline: r, children: /* @__PURE__ */ t(Te, {}) })
  ] }) }) : null;
};
function pn(e) {
  if (!(typeof e > "u" || e instanceof RegExp))
    return typeof e == "object" ? e.value : e;
}
const _ = ({
  name: e,
  label: n,
  labelIcon: r,
  error: a,
  children: l,
  ...i
}) => /* @__PURE__ */ b(
  Ve,
  {
    label: n || e,
    fieldId: e,
    labelIcon: r ? /* @__PURE__ */ t(ze, { helpText: r, fieldLabelId: e }) : void 0,
    ...i,
    children: [
      l,
      a && /* @__PURE__ */ t(je, { "data-testid": `${e}-helper`, message: a.message })
    ]
  }
), cr = ({
  name: e,
  label: n,
  controller: r,
  labelIcon: a,
  ...l
}) => {
  const {
    control: i,
    formState: { errors: s }
  } = te();
  return /* @__PURE__ */ t(
    _,
    {
      name: e,
      label: n,
      isRequired: r.rules?.required === !0,
      error: s[e],
      labelIcon: a,
      children: /* @__PURE__ */ t(
        U,
        {
          ...r,
          name: e,
          control: i,
          render: ({ field: c }) => {
            const o = !!r.rules?.required, m = pn(r.rules?.min), f = c.value ?? r.defaultValue, h = (d) => c.onChange(
              m !== void 0 ? Math.max(d, Number(m)) : d
            );
            return /* @__PURE__ */ t(
              dt,
              {
                ...l,
                id: e,
                value: f,
                validated: s[e] ? M.error : M.default,
                required: o,
                min: Number(m),
                max: Number(r.rules?.max),
                onPlus: () => h(f + 1),
                onMinus: () => h(f - 1),
                onChange: (d) => {
                  const u = Number(d.currentTarget.value);
                  h(isNaN(u) ? r.defaultValue : u);
                }
              }
            );
          }
        }
      )
    }
  );
}, fn = ({
  hasReveal: e = !0,
  innerRef: n,
  ...r
}) => {
  const { t: a } = Ce(), [l, i] = S(!0);
  return /* @__PURE__ */ b(pe, { children: [
    /* @__PURE__ */ t(se, { isFill: !0, children: /* @__PURE__ */ t(
      Q,
      {
        ...r,
        type: l ? "password" : "text",
        ref: n
      }
    ) }),
    e && /* @__PURE__ */ t(
      P,
      {
        variant: "control",
        "aria-label": a("showPassword"),
        onClick: () => i(!l),
        children: l ? /* @__PURE__ */ t(Pt, {}) : /* @__PURE__ */ t(Dt, {})
      }
    )
  ] });
}, Je = Ot(
  (e, n) => /* @__PURE__ */ t(fn, { ...e, innerRef: n })
);
Je.displayName = "PasswordInput";
const dr = (e) => {
  const { labelIcon: n, ...r } = e, a = !!e.rules?.required, l = e.defaultValue ?? "", { field: i, fieldState: s } = Ie({
    ...e,
    defaultValue: l
  });
  return /* @__PURE__ */ b(
    _,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: a,
      error: s.error,
      children: [
        /* @__PURE__ */ t(
          Je,
          {
            isRequired: a,
            id: e.name,
            "data-testid": e.name,
            validated: s.error ? M.error : M.default,
            isDisabled: e.isDisabled,
            ...r,
            ...i
          }
        ),
        e.helperText && /* @__PURE__ */ t(ue, { children: /* @__PURE__ */ t(he, { children: /* @__PURE__ */ t(me, { children: e.helperText }) }) })
      ]
    }
  );
}, gn = ({
  id: e,
  name: n,
  label: r,
  options: a,
  controller: l,
  labelIcon: i,
  ...s
}) => {
  const {
    control: c,
    formState: { errors: o }
  } = te(), [m, f] = S(!1);
  return /* @__PURE__ */ t(
    _,
    {
      name: n,
      label: r,
      isRequired: !!l.rules?.required,
      error: G(o, n),
      labelIcon: i,
      children: /* @__PURE__ */ t(
        U,
        {
          ...l,
          name: n,
          control: c,
          render: ({ field: { onChange: h, value: d } }) => /* @__PURE__ */ t(
            X,
            {
              ...s,
              onClick: () => f(!m),
              onOpenChange: () => f(!1),
              selected: $(a) ? a.filter(
                (u) => Array.isArray(d) ? d.includes(u.key) : d === u.key
              ).map((u) => u.value) : d,
              toggle: (u) => /* @__PURE__ */ t(
                K,
                {
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  ref: u,
                  onClick: () => f(!m),
                  isExpanded: m,
                  isFullWidth: !0,
                  status: G(o, n) ? fe.danger : void 0,
                  "aria-label": "toggle",
                  children: $(a) ? a.find(
                    (k) => k.key === (Array.isArray(d) ? d[0] : d)
                  )?.value : d
                }
              ),
              onSelect: (u, k) => {
                const x = k?.toString();
                h(Array.isArray(d) ? [x] : x), f(!1);
              },
              isOpen: m,
              children: /* @__PURE__ */ t(Z, { children: a.map((u) => /* @__PURE__ */ t(ge, { value: q(u), children: ke(u) ? u : u.value }, q(u))) })
            }
          )
        }
      )
    }
  );
}, z = (e) => ke(e) ? e : e.value, yn = ({
  id: e,
  name: n,
  label: r,
  options: a,
  controller: l,
  labelIcon: i,
  placeholderText: s,
  onFilter: c,
  variant: o,
  ...m
}) => {
  const {
    control: f,
    formState: { errors: h }
  } = te(), [d, u] = S(!1), [k, x] = S(""), [g, y] = S(0), O = ee(), v = a.filter(
    (p) => z(p).toLowerCase().startsWith(k.toLowerCase())
  ), C = D(
    () => v.map((p, I) => /* @__PURE__ */ t(
      ge,
      {
        value: q(p),
        isFocused: g === I,
        children: z(p)
      },
      q(p)
    )),
    [g, v]
  ), L = (p, I) => {
    const A = v[g];
    switch (u(!0), p.key) {
      case "Enter": {
        p.preventDefault(), o !== H.typeaheadMulti ? x(z(A)) : x(""), I.onChange(
          Array.isArray(I.value) ? [...I.value, q(A)] : q(A)
        ), u(!1), y(0);
        break;
      }
      case "Tab":
      case "Escape": {
        u(!1), I.onChange(void 0);
        break;
      }
      case "Backspace": {
        o === H.typeahead && I.onChange("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        p.preventDefault();
        let w = 0;
        p.key === "ArrowUp" && (g === 0 ? w = a.length - 1 : w = g - 1), p.key === "ArrowDown" && (g === a.length - 1 ? w = 0 : w = g + 1), y(w);
        break;
      }
    }
  };
  return /* @__PURE__ */ t(
    _,
    {
      name: n,
      label: r,
      isRequired: !!l.rules?.required,
      error: G(h, n),
      labelIcon: i,
      children: /* @__PURE__ */ t(
        U,
        {
          ...l,
          name: n,
          control: f,
          render: ({ field: p }) => /* @__PURE__ */ t(
            X,
            {
              ...m,
              onClick: () => u(!d),
              onOpenChange: () => u(!1),
              selected: $(a) ? a.filter(
                (I) => Array.isArray(p.value) ? p.value.includes(I.key) : p.value === I.key
              ).map((I) => I.value) : p.value,
              toggle: (I) => /* @__PURE__ */ t(
                K,
                {
                  ref: I,
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  variant: "typeahead",
                  onClick: () => u(!d),
                  isExpanded: d,
                  isFullWidth: !0,
                  status: G(h, n) ? fe.danger : void 0,
                  children: /* @__PURE__ */ b(Me, { isPlain: !0, children: [
                    /* @__PURE__ */ t(
                      Ne,
                      {
                        placeholder: s,
                        value: o === H.typeahead && p.value ? $(a) ? a.find(
                          (A) => A.key === (Array.isArray(p.value) ? p.value[0] : p.value)
                        )?.value : p.value : k,
                        onClick: () => u(!d),
                        onChange: (A, w) => {
                          x(w), c?.(w);
                        },
                        onKeyDown: (A) => L(A, p),
                        autoComplete: "off",
                        innerRef: O,
                        role: "combobox",
                        isExpanded: d,
                        "aria-controls": "select-typeahead-listbox",
                        children: o === H.typeaheadMulti && Array.isArray(p.value) && /* @__PURE__ */ t(Le, { "aria-label": "Current selections", children: p.value.map(
                          (A, w) => /* @__PURE__ */ t(
                            qe,
                            {
                              onClick: (T) => {
                                T.stopPropagation(), p.onChange(
                                  p.value.filter(
                                    (F) => F !== q(A)
                                  )
                                );
                              },
                              children: $(a) ? a.find((T) => A === T.key)?.value : z(A)
                            },
                            w
                          )
                        ) })
                      }
                    ),
                    /* @__PURE__ */ t(Be, { children: !!k && /* @__PURE__ */ t(
                      P,
                      {
                        variant: "plain",
                        onClick: () => {
                          p.onChange(void 0), x(""), O?.current?.focus();
                        },
                        "aria-label": "Clear input value",
                        children: /* @__PURE__ */ t(Ge, { "aria-hidden": !0 })
                      }
                    ) })
                  ] })
                }
              ),
              onSelect: (I, A) => {
                I?.stopPropagation();
                const w = A?.toString();
                o === H.typeaheadMulti && Array.isArray(p.value) ? p.value.includes(w) ? p.onChange(
                  p.value.filter((T) => T !== w)
                ) : p.onChange([...p.value, w]) : (p.onChange(Array.isArray(p.value) ? [w] : w), u(!1));
              },
              isOpen: d,
              children: /* @__PURE__ */ t(Z, { children: C })
            }
          )
        }
      )
    }
  );
};
var H = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(H || {});
const $ = (e) => typeof e[0] != "string", ke = (e) => typeof e == "string", q = (e) => ke(e) ? e : e.key, vn = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(gn, { ...n }) : /* @__PURE__ */ t(yn, { ...n, variant: e }), ur = ({
  labelOn: e,
  stringify: n,
  defaultValue: r,
  labelIcon: a,
  ...l
}) => {
  const s = r ?? (n ? "false" : !1), { control: c } = te();
  return /* @__PURE__ */ t(
    _,
    {
      hasNoPaddingTop: !0,
      name: l.name,
      isRequired: l.rules?.required === !0,
      label: l.label,
      labelIcon: a,
      children: /* @__PURE__ */ t(
        U,
        {
          control: c,
          name: l.name,
          defaultValue: s,
          render: ({ field: { onChange: o, value: m } }) => /* @__PURE__ */ t(
            ut,
            {
              ...l,
              id: l.name,
              "data-testid": l.name,
              label: e,
              isChecked: n ? m === "true" : m,
              onChange: (f, h) => {
                const d = n ? h.toString() : h;
                l.onChange?.(f, h), o(d);
              }
            }
          )
        }
      )
    }
  );
}, hr = (e) => {
  const n = !!e.rules?.required, r = e.defaultValue ?? "", { field: a, fieldState: l } = Ie({
    ...e,
    defaultValue: r
  });
  return /* @__PURE__ */ t(
    _,
    {
      isRequired: n,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: l.error,
      children: /* @__PURE__ */ t(
        _e,
        {
          isRequired: n,
          id: e.name,
          "data-testid": e.name,
          validated: l.error ? M.error : M.default,
          isDisabled: e.isDisabled,
          ...a
        }
      )
    }
  );
}, mr = (e) => {
  const { labelIcon: n, helperText: r, ...a } = e, l = !!e.rules?.required, i = e.defaultValue ?? "", { field: s, fieldState: c } = Ie({
    ...e,
    defaultValue: i
  });
  return /* @__PURE__ */ b(
    _,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: l,
      error: c.error,
      children: [
        /* @__PURE__ */ t(
          Q,
          {
            isRequired: l,
            id: e.name,
            "data-testid": e["data-testid"] || e.name,
            validated: c.error ? M.error : M.default,
            isDisabled: e.isDisabled,
            ...a,
            ...s
          }
        ),
        r && /* @__PURE__ */ t(ue, { children: /* @__PURE__ */ t(he, { children: /* @__PURE__ */ t(me, { children: r }) }) })
      ]
    }
  );
}, bn = _e, pr = ({ icon: e }) => {
  const n = Cn(e);
  return /* @__PURE__ */ t(ie, { size: "lg", children: /* @__PURE__ */ t(n, { alt: e }) });
};
function Cn(e) {
  switch (e) {
    case "github":
      return Wt;
    case "facebook":
      return Kt;
    case "gitlab":
      return Gt;
    case "google":
      return $t;
    case "linkedin":
    case "linkedin-openid-connect":
      return Ut;
    case "openshift-v3":
    case "openshift-v4":
      return Ht;
    case "stackoverflow":
      return _t;
    case "twitter":
      return Bt;
    case "microsoft":
      return qt;
    case "bitbucket":
      return Lt;
    case "instagram":
      return Nt;
    case "paypal":
      return Mt;
    default:
      return Vt;
  }
}
const In = "_title_180i0_2", kn = {
  title: In
}, Ye = ({
  id: e,
  title: n,
  headingLevel: r = "h1",
  size: a = "xl",
  ...l
}) => /* @__PURE__ */ t(
  ht,
  {
    headingLevel: r,
    size: a,
    className: kn.title,
    id: e,
    tabIndex: 0,
    ...l,
    children: n
  }
), xn = ({
  title: e,
  children: n,
  scrollId: r,
  className: a
}) => {
  const l = Ft();
  return /* @__PURE__ */ b(mt, { id: l, className: a, isFlat: !0, children: [
    /* @__PURE__ */ t(pt, { className: "kc-form-panel__header", children: /* @__PURE__ */ t(ft, { tabIndex: 0, children: /* @__PURE__ */ t(Ye, { id: r, title: e }) }) }),
    /* @__PURE__ */ t(gt, { className: "kc-form-panel__body", children: n })
  ] });
}, wn = (e) => {
  const { title: n, children: r, scrollId: a, ...l } = e;
  return /* @__PURE__ */ t("section", { ...l, style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: /* @__PURE__ */ b(Y, { children: [
    /* @__PURE__ */ t(Ye, { id: a, title: n }),
    r
  ] }) });
}, An = "_panel_1cdve_1", Tn = "_sticky_1cdve_5", Se = {
  panel: An,
  sticky: Tn
}, Sn = "kc-main-content-page-container", Oe = (e) => e.replace(/\s+/g, "-"), On = ({
  label: e,
  sections: n,
  borders: r = !1,
  ...a
}) => {
  const l = D(
    () => n.filter(({ isHidden: i }) => !i),
    [n]
  );
  return /* @__PURE__ */ b(yt, { hasGutter: !0, ...a, children: [
    /* @__PURE__ */ t(xe, { md: 8, sm: 12, children: l.map(({ title: i, panel: s }) => {
      const c = Oe(i.toLowerCase());
      return /* @__PURE__ */ t(Ue, { children: r ? /* @__PURE__ */ t(
        xn,
        {
          scrollId: c,
          title: i,
          className: Se.panel,
          children: s
        }
      ) : /* @__PURE__ */ t(wn, { scrollId: c, title: i, children: s }) }, i);
    }) }),
    /* @__PURE__ */ t(xe, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: /* @__PURE__ */ t(vt, { className: Se.sticky, children: /* @__PURE__ */ t(
      bt,
      {
        isVertical: !0,
        scrollableSelector: `#${Sn}`,
        label: e,
        offset: 100,
        children: l.map(({ title: i }) => {
          const s = Oe(i.toLowerCase());
          return (
            // note that JumpLinks currently does not work with spaces in the href
            /* @__PURE__ */ t(
              Ct,
              {
                href: `#${s}`,
                "data-testid": `jump-link-${s}`,
                children: i
              },
              i
            )
          );
        })
      }
    ) }) })
  ] });
}, Fn = (e, n, r) => (e.isValid || r) && (e.isDirty || n) && !e.isLoading && !e.isValidating && !e.isSubmitting, fr = ({
  formState: e,
  isDisabled: n = !1,
  allowInvalid: r = !1,
  allowNonDirty: a = !1,
  children: l,
  ...i
}) => /* @__PURE__ */ t(
  P,
  {
    variant: "primary",
    isDisabled: e && !Fn(e, a, r) || n,
    ...i,
    type: "submit",
    children: l
  }
), Rn = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, En = ({
  t: e,
  form: n,
  supportedLocales: r,
  currentLocale: a
}) => {
  const l = D(
    () => r.map((i) => ({
      key: i,
      value: e(`locale_${i}`, Rn(i) ?? i)
    })).sort((i, s) => i.value.localeCompare(s.value, a)),
    [r, a, e]
  );
  return l.length ? /* @__PURE__ */ t(Yt, { ...n, children: /* @__PURE__ */ t(
    vn,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: l,
      variant: l.length >= 10 ? "typeahead" : "single"
    }
  ) }) : null;
}, Qe = (e) => e?.includes("${"), Xe = (e) => e.substring(2, e.length - 1), B = (e, n, r, a) => {
  const l = n || r, i = Qe(l) ? Xe(l) : l, s = a ? `${a}.${i}` : i;
  return e(s || "");
}, ce = (e, n) => B(e, n.displayName, n.name), Pn = ["username", "firstName", "lastName", "email"], Ze = (e) => e && Pn.includes(e), N = (e) => `${Ze(e) ? "" : "attributes."}${e?.replaceAll(".", "🍺")}`, gr = (e) => e.replaceAll(".", "🍺"), yr = (e) => e.replaceAll("🍺", ".");
function vr(e, n, r) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((a) => {
    const l = Object.assign(
      {},
      a.params?.map((i) => r(Qe(i.toString()) ? Xe(i) : i))
    );
    n(N(a.field), {
      message: r(a.errorMessage, {
        ...l,
        defaultValue: a.errorMessage || a.field
      }),
      type: "server"
    });
  });
}
function ne({
  required: e,
  validators: n
}) {
  return e || Dn(n);
}
function Dn(e) {
  return e && "length" in e && "min" in e.length && typeof e.length.min == "number" ? e.length.min > 0 : !1;
}
function br(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: n } = e;
  return Fe(n) ? !0 : typeof n != "object" || n === null || !("errors" in n) || !Array.isArray(n.errors) ? !1 : n.errors.every(Fe);
}
function Fe(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const W = ({
  t: e,
  form: n,
  attribute: r,
  renderer: a,
  children: l
}) => {
  const i = B(
    e,
    r.annotations?.inputHelperTextBefore
  ), {
    formState: { errors: s }
  } = n, c = a?.(r), o = G(s, N(r.name));
  return /* @__PURE__ */ b(
    Ve,
    {
      label: ce(e, r) || "",
      fieldId: r.name,
      isRequired: ne(r),
      labelIcon: i ? /* @__PURE__ */ t(ze, { helpText: i, fieldLabelId: r.name }) : void 0,
      children: [
        c ? /* @__PURE__ */ b(pe, { children: [
          l,
          c
        ] }) : l,
        o && /* @__PURE__ */ t(
          je,
          {
            "data-testid": `${r.name}-helper`,
            message: o.message
          }
        )
      ]
    },
    r.name
  );
}, Vn = ({
  t: e,
  form: n,
  attribute: r,
  renderer: a,
  ...l
}) => /* @__PURE__ */ t(W, { t: e, form: n, attribute: r, renderer: a, children: /* @__PURE__ */ t(
  Mn,
  {
    t: e,
    form: n,
    "aria-label": ce(e, r),
    name: N(r.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: ce(e, r)
    }),
    ...l
  }
) }), Mn = ({
  t: e,
  name: n,
  inputType: r,
  form: a,
  addButtonLabel: l,
  isDisabled: i = !1,
  defaultValue: s,
  id: c,
  ...o
}) => {
  const { register: m, setValue: f, control: h } = a, d = Qt({
    name: n,
    control: h,
    defaultValue: s || ""
  }), u = D(() => Array.isArray(d) && d.length !== 0 ? d : s || [""], [d]), k = (v) => {
    y([...u.slice(0, v), ...u.slice(v + 1)]);
  }, x = () => {
    y([...u, ""]);
  }, g = (v, C) => {
    y([...u.slice(0, v), C, ...u.slice(v + 1)]);
  }, y = (v) => {
    const C = v.flatMap((L) => L);
    f(n, C, {
      shouldDirty: !0
    });
  }, O = r.startsWith("html") ? r.substring(6) : "text";
  return be(() => {
    m(n);
  }, [m]), /* @__PURE__ */ t("div", { id: c, children: u.map((v, C) => /* @__PURE__ */ b(Ue, { children: [
    /* @__PURE__ */ b(pe, { children: [
      /* @__PURE__ */ t(se, { isFill: !0, children: /* @__PURE__ */ t(
        Q,
        {
          "data-testid": n + C,
          onChange: (L, p) => g(C, p),
          name: `${n}.${C}.value`,
          value: v,
          isDisabled: i,
          type: O,
          ...o
        }
      ) }),
      /* @__PURE__ */ t(se, { children: /* @__PURE__ */ t(
        P,
        {
          "data-testid": "remove" + C,
          variant: we.link,
          onClick: () => k(C),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: u.length === 1 || i,
          children: /* @__PURE__ */ t(jt, {})
        }
      ) })
    ] }),
    C === u.length - 1 && /* @__PURE__ */ b(
      P,
      {
        variant: we.link,
        onClick: x,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !v || i,
        children: [
          /* @__PURE__ */ t(zt, {}),
          " ",
          e(l || "add")
        ]
      }
    )
  ] }, C)) });
}, Re = (e) => {
  const { form: n, inputType: r, attribute: a } = e, l = ne(a), i = r.startsWith("multiselect"), s = i ? It : kt, c = a.validators?.options?.options || [], o = a.annotations?.inputOptionLabels || {}, m = a.annotations?.inputOptionLabelsI18nPrefix;
  return /* @__PURE__ */ t(W, { ...e, children: /* @__PURE__ */ t(
    U,
    {
      name: N(a.name),
      control: n.control,
      defaultValue: "",
      render: ({ field: f }) => /* @__PURE__ */ t(Y, { children: c.map((h) => /* @__PURE__ */ t(
        s,
        {
          id: h,
          "data-testid": h,
          label: B(e.t, o[h], h, m),
          value: h,
          isChecked: f.value.includes(h),
          onChange: () => {
            i ? f.value.includes(h) ? f.onChange(
              f.value.filter((d) => d !== h)
            ) : f.onChange([...f.value, h]) : f.onChange([h]);
          },
          readOnly: a.readOnly,
          isRequired: l
        },
        h
      )) })
    }
  ) });
}, Nn = ({
  toggleId: e,
  onToggle: n,
  onSelect: r,
  selections: a,
  isOpen: l,
  menuAppendTo: i,
  direction: s,
  width: c,
  maxHeight: o,
  toggleIcon: m,
  className: f,
  children: h,
  ...d
}) => {
  const [u, k] = S(!1), x = ee(), g = () => {
    k(!u), n(!u);
  }, y = () => i === "parent" && x.current?.parentElement || "inline", O = $e.toArray(
    h
  );
  return /* @__PURE__ */ t(
    X,
    {
      ref: x,
      maxMenuHeight: J(o),
      isScrollable: !0,
      popperProps: {
        appendTo: y(),
        direction: s,
        width: J(c)
      },
      ...d,
      onClick: g,
      onOpenChange: (v) => k(v),
      selected: a,
      onSelect: (v, C) => {
        r?.(C || ""), g();
      },
      toggle: (v) => /* @__PURE__ */ t(
        K,
        {
          id: e,
          ref: v,
          className: f,
          onClick: g,
          isExpanded: l,
          "aria-label": d["aria-label"],
          icon: m,
          isFullWidth: !0,
          children: O.find((C) => C.props.value === a)?.props.children || a || d["aria-label"]
        }
      ),
      isOpen: l,
      children: /* @__PURE__ */ t(Z, { children: h })
    }
  );
}, Ln = ({
  toggleId: e,
  onSelect: n,
  onToggle: r,
  onFilter: a,
  variant: l,
  validated: i,
  placeholderText: s,
  maxHeight: c,
  width: o,
  toggleIcon: m,
  direction: f,
  selections: h,
  typeAheadAriaLabel: d,
  chipGroupComponent: u,
  chipGroupProps: k,
  footer: x,
  children: g,
  ...y
}) => {
  const [O, v] = S(""), [C, L] = S(0), p = ee(), I = $e.toArray(
    g
  ), A = () => {
    r?.(!y.isOpen);
  }, w = (T) => {
    const F = I[C];
    switch (r?.(!0), T.key) {
      case "Enter": {
        T.preventDefault(), l !== V.typeaheadMulti ? v(F.props.value) : v(""), n?.(F.props.value), r?.(!1), L(0);
        break;
      }
      case "Escape": {
        r?.(!1);
        break;
      }
      case "Backspace": {
        l === V.typeahead && n?.("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        T.preventDefault();
        let R = 0;
        T.key === "ArrowUp" && (C === 0 ? R = I.length - 1 : R = C - 1), T.key === "ArrowDown" && (C === I.length - 1 ? R = 0 : R = C + 1), L(R);
        break;
      }
    }
  };
  return /* @__PURE__ */ b(
    X,
    {
      ...y,
      onClick: A,
      onOpenChange: (T) => r?.(T),
      onSelect: (T, F) => n?.(F || ""),
      maxMenuHeight: J(c),
      popperProps: { direction: f, width: J(o) },
      toggle: (T) => /* @__PURE__ */ t(
        K,
        {
          ref: T,
          id: e,
          variant: "typeahead",
          onClick: () => r?.(!0),
          icon: m,
          isExpanded: y.isOpen,
          isFullWidth: !0,
          status: i === "error" ? fe.danger : void 0,
          children: /* @__PURE__ */ b(Me, { isPlain: !0, children: [
            /* @__PURE__ */ t(
              Ne,
              {
                placeholder: s,
                value: l === V.typeahead && h ? h : O,
                onClick: A,
                onChange: (F, R) => {
                  v(R), a?.(R);
                },
                onKeyDown: (F) => w(F),
                autoComplete: "off",
                innerRef: p,
                role: "combobox",
                isExpanded: y.isOpen,
                "aria-controls": "select-typeahead-listbox",
                "aria-label": d,
                children: l === V.typeaheadMulti && Array.isArray(h) && (u || /* @__PURE__ */ t(Le, { ...k, children: h.map((F, R) => /* @__PURE__ */ t(
                  qe,
                  {
                    onClick: (et) => {
                      et.stopPropagation(), n?.(F);
                    },
                    children: F
                  },
                  R
                )) }))
              }
            ),
            /* @__PURE__ */ t(Be, { children: !!O && /* @__PURE__ */ t(
              P,
              {
                variant: "plain",
                onClick: () => {
                  n?.(""), v(""), a?.(""), p?.current?.focus();
                },
                "aria-label": "Clear input value",
                children: /* @__PURE__ */ t(Ge, { "aria-hidden": !0 })
              }
            ) })
          ] })
        }
      ),
      children: [
        /* @__PURE__ */ t(Z, { children: g }),
        x && /* @__PURE__ */ t(xt, { children: x })
      ]
    }
  );
};
var V = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(V || {});
const J = (e) => typeof e == "number" ? e + "px" : e, qn = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(Nn, { ...n }) : /* @__PURE__ */ t(Ln, { ...n, variant: e }), Ee = (e) => {
  const { t: n, form: r, inputType: a, attribute: l } = e, [i, s] = S(!1), [c, o] = S(""), m = a === "multiselect", f = (g, y) => {
    m ? y.value.includes(g) ? y.onChange(y.value.filter((O) => O !== g)) : Array.isArray(y.value) ? y.onChange([...y.value, g]) : y.onChange([g]) : y.onChange(g === y.value ? "" : g);
  }, h = l.validators?.options?.options || [], d = l.annotations?.inputOptionLabels || {}, u = l.annotations?.inputOptionLabelsI18nPrefix, k = (g) => B(e.t, d[g], g, u), x = (g) => h.filter(
    (y) => k(y).toLowerCase().includes(c.toLowerCase())
  ).map((y) => /* @__PURE__ */ t(
    ge,
    {
      selected: g === y,
      value: y,
      children: k(y)
    },
    y
  ));
  return /* @__PURE__ */ t(W, { ...e, children: /* @__PURE__ */ t(
    U,
    {
      name: N(l.name),
      defaultValue: "",
      control: r.control,
      render: ({ field: g }) => /* @__PURE__ */ t(
        qn,
        {
          toggleId: l.name,
          onToggle: (y) => s(y),
          onClear: () => f("", g),
          onSelect: (y) => {
            const O = y.toString();
            f(O, g), Array.isArray(g.value) || s(!1);
          },
          selections: m && Array.isArray(g.value) ? g.value : k(g.value),
          variant: m ? V.typeaheadMulti : h.length >= 10 ? V.typeahead : V.single,
          "aria-label": n("selectOne"),
          isOpen: i,
          isDisabled: l.readOnly,
          onFilter: (y) => (o(y), x(g.value)),
          children: x(g.value)
        }
      )
    }
  ) });
}, Bn = (e) => {
  const { form: n, attribute: r } = e, a = ne(r);
  return /* @__PURE__ */ t(W, { ...e, children: /* @__PURE__ */ t(
    bn,
    {
      id: r.name,
      "data-testid": r.name,
      ...n.register(N(r.name)),
      cols: r.annotations?.inputTypeCols,
      rows: r.annotations?.inputTypeRows,
      readOnly: r.readOnly,
      isRequired: a
    }
  ) });
}, E = (e) => {
  const { form: n, inputType: r, attribute: a } = e, l = ne(a), i = r.startsWith("html") ? r.substring(6) : "text";
  return /* @__PURE__ */ t(W, { ...e, children: /* @__PURE__ */ t(
    Q,
    {
      id: a.name,
      "data-testid": a.name,
      type: i,
      placeholder: B(
        e.t,
        a.annotations?.inputTypePlaceholder,
        a.name,
        a.annotations?.inputOptionLabelsI18nPrefix
      ),
      readOnly: a.readOnly,
      isRequired: l,
      ...n.register(N(a.name))
    }
  ) });
}, de = {
  text: E,
  textarea: Bn,
  select: Ee,
  "select-radiobuttons": Re,
  multiselect: Ee,
  "multiselect-checkboxes": Re,
  "html5-email": E,
  "html5-tel": E,
  "html5-url": E,
  "html5-number": E,
  "html5-range": E,
  "html5-datetime-local": E,
  "html5-date": E,
  "html5-month": E,
  "html5-time": E,
  "multi-input": Vn
}, Cr = ({
  t: e,
  form: n,
  userProfileMetadata: r,
  supportedLocales: a,
  currentLocale: l,
  hideReadOnly: i = !1,
  renderer: s
}) => {
  const c = D(() => {
    if (!r.attributes)
      return [];
    const o = i ? r.attributes.filter(({ readOnly: m }) => !m) : r.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...r.groups ?? []
    ].map((m) => ({
      group: m,
      attributes: o.filter(
        (f) => f.group === m.name
      )
    }));
  }, [
    i,
    r.groups,
    r.attributes
  ]);
  return c.length === 0 ? null : /* @__PURE__ */ t(
    On,
    {
      label: e("jumpToSection"),
      sections: c.filter((o) => o.attributes.length > 0).map(({ group: o, attributes: m }) => ({
        title: B(e, o.displayHeader, o.name) || e("general"),
        panel: /* @__PURE__ */ b("div", { className: "pf-v5-c-form", children: [
          o.displayDescription && /* @__PURE__ */ t(le, { className: "pf-v5-u-pb-lg", children: B(e, o.displayDescription, "") }),
          m.map((f) => /* @__PURE__ */ t(
            _n,
            {
              t: e,
              form: n,
              supportedLocales: a,
              currentLocale: l,
              renderer: s,
              attribute: f
            },
            f.name
          ))
        ] })
      }))
    }
  );
}, _n = ({
  t: e,
  form: n,
  renderer: r,
  supportedLocales: a,
  currentLocale: l,
  attribute: i
}) => {
  const s = n.watch(
    N(i.name)
  ), c = D(() => Un(i), [i]), o = i.multivalued || Gn(s) && i.annotations?.inputType === void 0 ? de["multi-input"] : de[c];
  return i.name === "locale" ? /* @__PURE__ */ t(
    En,
    {
      form: n,
      supportedLocales: a,
      currentLocale: l,
      t: e,
      attribute: i
    }
  ) : /* @__PURE__ */ t(
    o,
    {
      t: e,
      form: n,
      inputType: c,
      attribute: i,
      renderer: r
    }
  );
}, Hn = "text";
function Un(e) {
  if (Ze(e.name))
    return "text";
  const n = e.annotations?.inputType;
  return $n(n) ? n : Hn;
}
const $n = (e) => typeof e == "string" && e in de, Gn = (e) => Array.isArray(e) && e.length > 1, Kn = ({
  className: e = "",
  border: n,
  size: r = "md"
}) => /* @__PURE__ */ b(
  "svg",
  {
    className: tn(
      j.avatar,
      j.modifiers[r],
      n === "light" && j.modifiers.light,
      n === "dark" && j.modifiers.dark,
      e
    ),
    enableBackground: "new 0 0 36 36",
    version: "1.1",
    viewBox: "0 0 36 36",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ t(
        "circle",
        {
          style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
          cx: "18",
          cy: "18.5",
          r: "18"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ t(
        "filter",
        {
          id: "b",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          filterUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("feColorMatrix", { values: "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" })
        }
      ) }),
      /* @__PURE__ */ t(
        "mask",
        {
          id: "a",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          maskUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("g", { style: { filter: 'url("#b")' }, children: /* @__PURE__ */ t(
            "circle",
            {
              style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
              cx: "18",
              cy: "18.5",
              r: "18"
            }
          ) })
        }
      ),
      /* @__PURE__ */ t("g", { style: { filter: 'url("#a")' }, children: /* @__PURE__ */ b("g", { transform: "translate(5.04 6.88)", children: [
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m22.6 18.1c-1.1-1.4-2.3-2.2-3.5-2.6s-1.8-0.6-6.3-0.6-6.1 0.7-6.1 0.7 0 0 0 0c-1.2 0.4-2.4 1.2-3.4 2.6-2.3 2.8-3.2 12.3-3.2 14.8 0 3.2 0.4 12.3 0.6 15.4 0 0-0.4 5.5 4 5.5l-0.3-6.3-0.4-3.5 0.2-0.9c0.9 0.4 3.6 1.2 8.6 1.2 5.3 0 8-0.9 8.8-1.3l0.2 1-0.2 3.6-0.3 6.3c3 0.1 3.7-3 3.8-4.4s0.6-12.6 0.6-16.5c0.1-2.6-0.8-12.1-3.1-15z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m22.5 26c-0.1-2.1-1.5-2.8-4.8-2.8l2.2 9.6s1.8-1.7 3-1.8c0 0-0.4-4.6-0.4-5z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m12.7 13.2c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.8 6.4-6.4 6.4z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.08,
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#231F20"
            },
            d: "m9.4 6.8c0-3 2.1-5.5 4.9-6.3-0.5-0.1-1-0.2-1.6-0.2-3.5 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4c0.6 0 1.1-0.1 1.6-0.2-2.8-0.6-4.9-3.1-4.9-6.1z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m8.3 22.4c-2 0.4-2.9 1.4-3.1 3.5l-0.6 18.6s1.7 0.7 3.6 0.9l0.1-23z"
          }
        )
      ] }) })
    ]
  }
), Pe = ({
  isKebab: e = !1,
  title: n,
  dropDownItems: r,
  ...a
}) => {
  const [l, i] = S(!1);
  return /* @__PURE__ */ t(
    wt,
    {
      ...a,
      popperProps: {
        position: "right"
      },
      onOpenChange: (s) => i(s),
      toggle: (s) => /* @__PURE__ */ t(
        K,
        {
          "data-testid": `${a["data-testid"]}-toggle`,
          ref: s,
          onClick: () => i(!l),
          isExpanded: l,
          variant: e ? "plain" : "default",
          children: e ? /* @__PURE__ */ t(Jt, {}) : n
        }
      ),
      isOpen: l,
      children: /* @__PURE__ */ t(At, { children: r })
    }
  );
};
function Wn(e, n) {
  if (!e)
    return n("unknownUser");
  const r = e.given_name, a = e.family_name, l = e.preferred_username;
  return r && a ? n("fullName", { givenName: r, familyName: a }) : r || a || l || n("unknownUser");
}
const Ir = ({
  keycloak: e,
  brand: { href: n, ...r },
  avatar: a,
  features: {
    hasLogout: l = !0,
    hasManageAccount: i = !0,
    hasUsername: s = !0
  } = {},
  kebabDropdownItems: c,
  dropdownItems: o = [],
  toolbarItems: m,
  ...f
}) => {
  const { t: h } = Ce(), d = [];
  i && d.push(
    /* @__PURE__ */ t(
      Ae,
      {
        onClick: () => e.accountManagement(),
        children: h("manageAccount")
      },
      "manageAccount"
    )
  ), l && d.push(
    /* @__PURE__ */ t(Ae, { onClick: () => e.logout(), children: h("signOut") }, "signOut")
  );
  const u = e.idTokenParsed?.picture;
  return /* @__PURE__ */ t(
    Xt,
    {
      ...f,
      logo: /* @__PURE__ */ t(Tt, { ...r }),
      logoProps: { href: n },
      headerTools: /* @__PURE__ */ b(Zt, { children: [
        /* @__PURE__ */ b(en, { children: [
          /* @__PURE__ */ t(
            ae,
            {
              visibility: {
                md: "hidden"
              },
              children: /* @__PURE__ */ t(
                Pe,
                {
                  "data-testid": "options-kebab",
                  isKebab: !0,
                  dropDownItems: [
                    ...c || o,
                    d
                  ]
                }
              )
            }
          ),
          /* @__PURE__ */ t(ae, { children: m }),
          /* @__PURE__ */ t(
            ae,
            {
              visibility: {
                default: "hidden",
                md: "visible"
              },
              children: /* @__PURE__ */ t(
                Pe,
                {
                  "data-testid": "options",
                  dropDownItems: [...o, d],
                  title: s ? Wn(e.idTokenParsed, h) : void 0
                }
              )
            }
          )
        ] }),
        u || a?.src ? /* @__PURE__ */ t(St, { src: u, alt: h("avatar"), ...a }) : /* @__PURE__ */ t(Kn, { ...a })
      ] })
    }
  );
};
export {
  nn as AlertProvider,
  or as ContinueCancelModal,
  rn as ErrorPage,
  je as FormErrorText,
  xn as FormPanel,
  fr as FormSubmitButton,
  hn as Help,
  ze as HelpItem,
  pr as IconMapper,
  Ir as KeycloakMasthead,
  ir as KeycloakProvider,
  qn as KeycloakSelect,
  bn as KeycloakTextArea,
  cr as NumberControl,
  dr as PasswordControl,
  Je as PasswordInput,
  On as ScrollForm,
  vn as SelectControl,
  H as SelectVariant,
  ur as SwitchControl,
  hr as TextAreaControl,
  mr as TextControl,
  Cr as UserProfileFields,
  gr as beerify,
  ln as createNamedContext,
  yr as debeerify,
  sr as getInjectedEnvironment,
  sn as isDefined,
  br as isUserProfileError,
  B as label,
  Sn as mainPageContentId,
  vr as setUserProfileServerError,
  ar as useAlerts,
  lr as useEnvironment,
  un as useHelp,
  on as useRequiredContext,
  dn as useStoredState
};
